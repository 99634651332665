<template>
  <div class="Feedback">
    <a-spin :spinning="spinning">
    <div class="header">
      <div class="header-content">
        状态:
        <a-radio-group button-style="solid" default-value="" @change="statusButton($event)">
          <a-radio-button value="">全部</a-radio-button>
          <a-radio-button value="0" style="margin-left: 10px">待回复</a-radio-button>
          <a-radio-button value="1">已回复</a-radio-button>
        </a-radio-group>
      </div>
    </div>

    <div class="main">
      <!----------------------table 列表区域---------------------->
      <a-table
          :columns="tableColumns"
          :data-source="tableData"
          rowKey="id"
          :pagination="false"
      >
        <template slot="status" slot-scope="text,item">
          <span v-if="item.isStatus === 1">
            <a-badge status="success" /> 已回复
          </span>
          <span v-else>
            <a-badge status="processing" /> 待回复
          </span>
        </template>
        <template slot="operation" slot-scope="text,item,index">
          <span v-if="item.isStatus === 1">
            <a @click="seeOpen(item)">查看</a>
          </span>
          <span v-else>
            <a @click="handleOpen(item)">回复</a>
            <a style="margin-left: 10px" @click="seeOpen(item)">查看</a>
          </span>
        </template>
      </a-table>
      <div style="background-color: #fff;width:100%;height: 100px;">
        <a-pagination style="float: right;margin-top: 25px;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
                      @change="pageChange" @showSizeChange="pageChange"  v-model="pagination.current" :total=pagination.total
        />
      </div>

      <!----------------------查看抽屉区域---------------------->
      <a-drawer
          title="查看"
          :width="620"
          :visible="seeVisible"
          @close="seeOnClose"
      >
        <div class="drawerComplaint">
          <a-descriptions title="反馈信息" :column="2">
            <a-descriptions-item label="反馈人姓名">
              {{seeForm.feedbackPeople}}
            </a-descriptions-item>
            <a-descriptions-item label="联系电话">
              {{seeForm.tel}}
            </a-descriptions-item>
            <a-descriptions-item label="所在单位">
              {{seeForm.unit}}
            </a-descriptions-item>
            <a-descriptions-item label="反馈时间">
              {{seeForm.createdTime}}
            </a-descriptions-item>
            <a-descriptions-item label="反馈渠道" :span="2">
              {{seeForm.channel}}
            </a-descriptions-item>
            <a-descriptions-item label="标题" :span="2">
              {{seeForm.title}}
            </a-descriptions-item>
            <a-descriptions-item label="反馈内容" :span="2">
              {{seeForm.content}}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="drawerComplaint-image">
          <img :src="item" style="width: 100px;height: 60px" @click="imageOpen($event)" v-for="item in seeForm.images">
        </div>
        <div class="drawerHandle" v-if="seeForm.isStatus === 1">
            <a-divider />
          <a-descriptions title="回复信息" :column="2">
            <a-descriptions-item label="回复状态" :span="2">
              {{seeForm.isStatus === 1 ? '已回复' : '未回复'}}
            </a-descriptions-item>
            <a-descriptions-item label="回复人">
              {{seeForm.userName}}
            </a-descriptions-item>
            <a-descriptions-item label="回复时间">
              {{seeForm.replyTime}}
            </a-descriptions-item>
            <a-descriptions-item label="回复内容" :span="2">
              {{seeForm.replyContent}}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="seeOnClose">
            取消
          </a-button>
          <a-button type="primary">
            确定
          </a-button>
        </div>
      </a-drawer>
    </div>

    <!----------------------点击回复弹出框---------------------->
    <a-modal
        title="回复"
        :visible="handleVisible"
        @ok="handleOk"
        @cancel="handleClose"
    >
      <a-form-model
          :model="handleForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          ref="handleForm"
          :rules="rules"
      >
        <a-form-model-item label="回复人">
          {{handleForm.user_name}}
        </a-form-model-item>
        <a-form-model-item label="回复方案" prop="text">
          <a-textarea placeholder="请输入" v-model="handleForm.text"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
        :visible="imageVisible"
        :footer="null"
        @cancel="handleCancel"
        style="max-width: 800px;"
    >
      <div style="width: 100%;height: 100%;display: flex;justify-content: space-around;align-content: center;">
        <img :src="modalImage" style="max-width: 480px">
      </div>
    </a-modal>
    </a-spin>
  </div>
</template>

<script>
import {postFeedback,putFeedbackReply} from "@/service/systemApi";
export default {
  name: 'userComplaints',
  data () {
    return {
      status: '',
      modalImage: '',
      handleVisible: false,
      seeVisible: false,
      handleForm: {},
      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      seeForm: {},
      pagination: {   //用于分页
        total: 0,
        current: 1,
        pageSize: 10,
      },
      tableColumns: [
        {
          title: '标题',
          width: '20%',
          dataIndex: 'title'
        },
        {
          title: '反馈人',
          width: '10%',
          dataIndex: 'feedbackPeople'
        },
        {
          title: '联系电话',
          width: '15%',
          dataIndex: 'tel'
        },
        {
          title: '单位',
          width: '15%',
          align: 'left',
          dataIndex: 'unit'
        },
        {
          title: '反馈渠道',
          width: '15%',
          dataIndex: 'channel'
        },
        {
          title: '状态',
          width: '10%',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '操作',
          width: '10%',
          scopedSlots: {customRender: 'operation'}
        }
      ],
      tableData: [],
      image: [],
      imageVisible: false,
      spinning: false,
      rules: {
        text: [{required: true, message: '请输入回复内容', trigger: 'blur'}]
      }
    }// dataReturn
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"意见反馈管理")
    this.getFeedbackTable()
  },
  methods: {
    seeOnClose () { // 查看关闭
      this.seeVisible = false
    },
    async seeOpen (row) { // 查看打开
      this.seeForm = {}
      this.spinning = true
      const data={
        id:row.id
      }
      const response = await postFeedback(data)
      if (response.code === 0) {
          this.seeForm = response.data.list[0]
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
      this.seeVisible = true
    },
    async handleOk () { // 处理提交
      this.$refs.handleForm.validate(async valid => {
        if (valid) {
          this.spinning = true
          let data = {
            "id": this.handleForm.replyId,
            "replyContent": this.handleForm.text
          }
          const response = await putFeedbackReply(data)
          if (response.code === 0) {
            await this.getFeedbackTable(this.status)
            this.$message.success("回复成功")
            this.handleVisible = false
          }else {
            this.$message.warning(response.message)
          }
          this.spinning = false
        }else {
          this.$message.warning('请填写完整表单')
        }
      })
    },
    handleClose () { // 处理关闭
      this.handleVisible = false
    },
    handleOpen (row) { // 处理打开
      let temp = window.localStorage.getItem("user_info") // 获取当前登录账户 id
      this.handleForm =  JSON.parse(temp)
      this.handleForm.replyId = row.id
      this.handleVisible = true
    },
    async getFeedbackTable (status, id) { // 获取 table 列表数据
      if (status === undefined) status = ''
      if (id === undefined) id = ''
      this.spinning = true
      const data={
        id:id,
        state:status,
        pageNum:this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      const response = await postFeedback(data)
      if ( response.code === 0 ) {
        this.tableData = response.data.list
        this.pagination.total=response.data.total
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    pageChange(page_no, page_size){
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      this.getFeedbackTable(this.status, '')
    },
    imageOpen (e) { // 点击图片放大方法
      this.imageVisible = true
      this.modalImage = e.target.src
    },
    handleCancel () { // 放大后的图片取消方法
      this.imageVisible = false
    },
     statusButton (e) { // 点击状态按钮切换 table 列表数据
      this.spinning = true
      this.status = e.target.value
      this.pagination.current=1
      this.getFeedbackTable(e.target.value, '')
      this.spinning = false
    }
  }
}
</script>

<style lang="scss" scoped>
.Feedback {
  margin: -15px;
  .header {
    height: 75px;
    border-bottom: 15px solid rgba(240, 242, 245, 1);
    .header-content {
      margin: 25px 20px;
    }
  }
  .main {
    margin: 15px;
  }
}
.drawerComplaint {
  max-height: 50%;
}
.drawerComplaint-image {
  position: relative;
  bottom: 0;
  left: 0;
  img {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
</style>